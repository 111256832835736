const posts = [
    {
        imageUrl: 'url(/images/dining.png)',
        publishDate: 'May 20, 2021',
        author: 'Bloom Team',
        title: 'Guide to the Perfect Dinner Date',
        path: 'perfect-first-date-guide',
        paragraphs: [
            {
                type: 'body',
                text: 'Everyone knows the classic dinner date - an intimate candlelit evening served with delicious food, good conversation, and occasional flirting. Yet so often people get it wrong. From the type of restaurant and etiquette to the post-dinner activity, we’ve got a few top tips to ensure you nail the romantic dinner.',
            },
            {
                type: 'header',
                text: '1. Picking the right restaurant',
            },
            {
                type: 'body',
                text: 'It might seem like a no-brainer, but deciding where and what to eat is an art in itself. The general rule of thumb is that fast food joints are out of the running and a 5-star restaurant with fuzzy dollar signs (and 12 course meals) might be uncomfortably fancy unless you’re already in a relationship.',
            },
            {
                type: 'body',
                text: 'We recommend finding a local gem that has some noteworthy dishes and a comfortable ambiance for conversation. Go for a place that is not too loud, but has some chatter in the background so that it’s not awkwardly intimate. It doesn’t need to be expensive, but it should be unique.',
            },
            {
                type: 'body',
                text: 'Check with your date ahead of time to make sure the type of food matches their preferences and dietary restrictions, and get familiar with the menu so there are no surprises. Make sure it’s a restaurant that lets you make a reservation, and bonus points if it’s in a more downtown location where you can take a stroll post-dinner (hint: to get dessert). During these pandemic times, a restaurant with an outdoor patio can help everyone feel more comfortable. Can’t find a place? Try using www.funday.gg and let the algorithm decide.',
            },
            {
                type: 'header',
                text: '2. Be prepared',
            },
            {
                type: 'body',
                text: 'For your date, a telltale sign of how much you care is your preparation. Reserve your table as early as possible, and confirm the time and place with your date (ideally a week in advance) so that they can block it off their calendar. Be courteous and let your date know if you can pick them up.',
            },
            {
                type: 'body',
                text: 'The day of your dinner, send a message to let them know you’re looking forward to the evening. This is a nice way to build anticipation and remind them that they’re on your mind! And it goes without saying that you should always arrive a little early for your date.',
            },
            {
                type: 'header',
                text: '3. Dress code',
            },
            {
                type: 'body',
                text: 'No matter the vibe of the restaurant, always dress in something polished and clean. For a foolproof look we recommend a crisp button-up shirt, watch, and slightly dressier shoes. Wear an appropriate amount of cologne and keep your hair well-groomed.',
            },
            {
                type: 'header',
                text: '4. Dinner etiquette',
            },
            {
                type: 'body',
                text: 'When it comes to the main event, order some appetizers for the table so that you have some things to snack on as you talk. Keep your phone tucked away so that you’re not distracted during conversation. Nothing is worse than a dinner companion who’s more occupied by their phone.',
            },
            {
                type: 'body',
                text: 'More importantly, this is a moment where you can connect more deeply with your date. As you try to get to know them, don’t turn it into an interview and instead relax and enjoy the moment together. Flash a smile and remember to be kind to the waiter as well.',
            },
            {
                type: 'header',
                text: '5. Top the night off with a chill activity',
            },
            {
                type: 'body',
                text: 'The end of the night can make for an awkward moment. Should you part ways? What if you’re hitting it off? Have an activity in mind so that if all goes well, this is a good chance for you to suggest walking to a dessert place in the vicinity or chilling in a nearby park (if it isn’t too late). This is where you can get some intimate time alone and really get to know each other through some thought-provoking conversations.',
            },
        ],
    },
    {
        imageUrl: 'url(/images/hug.png)',
        publishDate: 'Mar 7, 2021',
        author: 'Bloom Team',
        title: 'Bloom — the serious dating app for modern people',
        path: 'bloom-the-serious-dating-app-for-modern-people',
        paragraphs: [
            {
                type: 'body',
                text: 'Hi friends, so you’re probably reading this because you’ve heard from a little bird that there’s this cool dating app being built for serious relationship seekers. Well, you heard right — and we’re more than happy to tell you all about it.',
            },
            {
                type: 'header',
                text: 'What doesn’t work now',
            },
            {
                type: 'body',
                text: 'Before we get into why we’re different from the millions of dating apps out there, let’s talk about the problem we’re tackling. Across the spectrum of dating apps, “serious dating” and “great user experience” don’t go together. The popular apps you’re most familiar with are focused on fast swipes and casual dating, while the ones that are focused on long-term and more serious relationships are poorly built, expensive and lackluster in experience. The idea that you can know someone through a few selfies and semi-funny prompts is a bit like trying to fish with a spoon — trust me, it’s hard.',
            },
            {
                type: 'body',
                text: 'And to be 100% clear, we have nothing against casual dating. We just think that for the group of people who are searching for a long-term relationship there should be a more dedicated place that offers that deeper level of connection. We aim to be that place.',
            },
            {
                type: 'header',
                text: 'How we are different',
            },
            {
                type: 'body',
                text: 'You might be asking — what is going to make Bloom stand out from the rest? Well, we’ve carefully focused on a few differentiators through plenty of interviewing, designing and digging deep in the world of attraction.',
            },
            {
                type: 'header',
                text: 'Here’s what we are doing:',
            },
            {
                type: 'body',
                text: 'Enabling Voice-chat (think voice messages): In a digital era where connections are hard to come by, we can all use a little more human touch. That’s why we’ll be doubling down on voice chat to help matches create a stronger bond and connect more intimately. And just like how selfies with puppies and corny but creative pick-up lines are tools of expression, focusing on voice-chat will give matches a chance to experience a different dimension of expression that’s more authentic, vulnerable, and intimate — and honestly, we think that’s pretty cool. We’re still going to have texting as an option so don’t worry if you can’t or don’t want to use your voice. Definitely give it a shot though when you get a chance on Bloom.',
            },
            {
                type: 'body',
                text: 'Human-first Matching Algorithm: Our approach in using AI to match awesome people like yourself with the right people is our bread and butter. Unlike other platforms, we’re less like a faulty algorithm and more like your personal matchmaker. We’re dedicated to getting a much more holistic understanding of your preferences so that we can suggest quality matches, and we’ll continuously get your input to adjust the matching based on your likes and dislikes. This is all controlled by you. We know finding that special someone is more than just a compatibility score — which is why we focus our efforts on making it as easy as possible for you to get matching with the right people.',
            },
            {
                type: 'body',
                text: 'Profiles as Individual Stories: Like we said earlier, a few pictures and some cute one-liner is not enough to really know someone and we mean that. That’s why we are approaching profiles like a mini-storybook of all our users on Bloom. When we suggest someone to you, you’ll get bite-sized snippets of who they are and ultimately, help you decide if the spark is there. That’s exciting.',
            },
            {
                type: 'body',
                text: 'Intentional Suggestions: The idea is quality over quantity. We curate a small batch of potential matches so that you’re not overwhelmed with mediocre options every day. Less endless swiping equals more time for the ones that matter.',
            },
            {
                type: 'body',
                text: 'The You’re-Not-Alone Approach: Let’s face it — online dating is hard and the pandemic isn’t making it any easier. We are taking a more hands-on approach to make sure our users are best equipped to find the love of their life and yes, delete poor little old us. We want that. That’s why we are building an in-app Profile Reviewer where users can submit their profile and real dating experts will look at them and make suggestions. The point is we got your back.',
            },
            {
                type: 'body',
                text: 'Look, we’re not here to get everyone and their moms to join Bloom. We’re not here for world domination. Our goal is to help people who are seeking genuine relationships find their special someone here at Bloom. Maybe their future kids can run for world domination but hey, that’s out of our hands at that point.',
            },
            {
                type: 'header',
                text: 'Who we are',
            },
            {
                type: 'body',
                text: 'Now, you might be asking “who are you to try to solve this momentous life issue?” Well, we are you. I mean — we are just like you, a group of friends who had enough of settling for the typical sh*tty dating app experience, don’t like other alternatives, and genuinely believe that there has to be a better way. So we decided to build it ourselves. To make this happen, we’re working alongside incredible relationship therapists, machine learning PhDs, clinical psychologists, dating experts, and most importantly, you — and we mean it.',
            },
            {
                type: 'body',
                text: 'The job to match people for real relationships is not easy so we need all the help we can get. If you can fill out this short little questionnaire after you’re done reading this semi-motivational and decently descriptive blog post, that’ll be great. You’re the best.',
            },
            {
                type: 'header',
                text: 'The progress',
            },
            {
                type: 'body',
                text: 'Hopefully, by this point, you are super rowdy and bubbling up with excitement. You might be wondering when is Bloom going to come out or which single friend you should invite and we’re glad you asked. We’re in the middle of getting our beta ready and constantly downing coffee and fiercely writing code to get this out. We’re also doing a lot of interviewing and speaking to everyone to learn about what and how we should be building Bloom so please reach out to us if you want to chat.',
            },
            {
                type: 'header',
                text: 'Stay connected',
            },
            {
                type: 'body',
                text: 'Meanwhile, follow our socials to keep up to date with the Bloom team. We know it’s going to be a journey to get Bloom to where we want it to be and we for sure know we can’t do it alone. Can’t wait to get you on Bloom!',
            },
            {
                type: 'body',
                text: 'Bloom team',
            },
            {
                type: 'body',
                text: 'www.lovebybloom.com',
            },
        ],
    },
    {
        imageUrl: 'url(/images/convos.png)',
        publishDate: 'Aug 31, 2021',
        author: 'Bloom Team',
        title: 'Introducing Convos, the card game that fosters connection',
        path: 'convos-card-game',
        paragraphs: [
            {
                type: 'body',
                text: 'At Bloom, we’re dedicated to helping people connect with one another in an authentic and genuine way. And now we’re building on top of that foundation with something new.',
            },
            {
                type: 'body',
                text: 'We’re thrilled to announce Convos, an online questions card game designed to help people break the ice and connect deeply through thoughtful conversation. It’s extremely easy to play. Grab some drinks, get comfortable with a date, spouse, friends, or family and take turns answering carefully crafted questions around life, relationships, perspectives, and big ideas — some are quirky while others are more reflective. There are a total of 10 levels of questions submitted by our expert conversationalists. While they increase in difficulty and become tougher to answer, you can always shuffle to draw a different question at each level. Don’t be surprised to end the game on an intimate note. The goal of Convos is to help you learn about each other’s unique views and perspectives.',
            },
            {
                type: 'body',
                text: 'We started building Bloom because dating apps were focused on the quantity of matches, not quality. And with Convos, we want to make it easier to foster deep, intentional, and meaningful relationships — through good conversations.',
            },
            {
                type: 'body',
                text: 'Give it a try at www.convosbybloom.com.',
            },
            {
                type: 'body',
                text: 'Happy connecting!',
            },
            {
                type: 'body',
                text: 'The Bloom Team',
            },
        ],
    },
];

export {
    posts,
};