import React from 'react';
import classNames from 'classnames';

import BlogPostCards from '../components/BlogPostCards';
import { posts } from '../posts/postData';

import './styles/Blog.scss';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            author: '',
            imageUrl: '',
            publishDate: '',
            paragraphs: '',
            title: '',
        };
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        
        const { history: { location: { search }} } = this.props;

        switch (search) {
            case '?name=perfect-first-date-guide':
                this.setState({
                    ...posts[0],
                });
                break;
            case '?name=bloom-the-serious-dating-app-for-modern-people':
                this.setState({
                    ...posts[1],
                });
                break;
            case '?name=convos-card-game':
                this.setState({
                    ...posts[2],
                });
                break;
            default:
                break;
        };
    };

    componentDidUpdate() {
        window.scrollTo(0, 0);
    };

    renderEmptyState = () => {
        return (
            <div className="Blog-emptyState">
                <p>Oops! Try going to the Bloom homepage instead.</p>
            </div>
        );
    };


    
    render() {
        let author;
        let imageUrl;
        let publishDate;
        let paragraphs;
        let title;

        if (this.props.location.state && this.props.location.state.author) {
            author = this.props.location.state.author;
            imageUrl = this.props.location.state.imageUrl;
            publishDate = this.props.location.state.publishDate;
            paragraphs = this.props.location.state.paragraphs;
            title = this.props.location.state.title;
        } else if (this.state.author) {
            author = this.state.author;
            imageUrl = this.state.imageUrl;
            publishDate = this.state.publishDate;
            paragraphs = this.state.paragraphs;
            title = this.state.title;
        } else {
            return this.renderEmptyState();
        }

        const remainingPosts = posts.filter(post => post.title !== title && post.title !== '');

        return (
            <div className="Blog">
                <div className="Blog-heroImageContainer">
                    <div
                        className="Blog-heroImage"
                        style={{ backgroundImage: imageUrl }}
                    />
                </div>

                <p className="Blog-title font-face-satoshi-bold">{title}</p>

                <div className="Blog-authorAvatarContainer">
                    <div className="Blog-authorAvatar">
                        <p className="Blog-authorAvatar-text">Bloom</p>
                    </div>
                </div>

                <div className="Blog-authorInfo">
                    <p className="publishDate">Published {publishDate}</p>
                    <p className="publishAuthor">By {author}</p>
                </div>

                <div className="Blog-content">
                    {
                        paragraphs.map(paragraph => {
                            const { type, text } = paragraph;
                            return (
                                <p className={classNames("Blog-content-text", { 'is-header': type === 'header' })}>{text}</p>            
                            );
                        })
                    }
                </div>

                <div className="Blog-suggestions">
                    <p className="Blog-suggestions-header">You might like</p>
                    <div className="Blog-posts">
                        <BlogPostCards posts={remainingPosts} />
                    </div>
                </div>
            </div>
        );
    };
};

export default Blog;