import React from 'react';

import './styles/Support.scss';

const supportQuestions = [
    {
        sectionHeader: 'Basic interactions',
        questions: [
            {
                question: 'How can I send a like?',
                answer: [
                    'Sending someone a Like is easy! Here are the steps:',
                    'break',
                    'Go to the Discover Tab > select a Profile > tap on the heart icon beside their topic or pictures. You will be asked to include a text or voice message before sending the Like.',
                    'break',
                    "Note that you will get a response in your Matches tab if the member likes you back. If you don't see any profiles on your Discover tab, it either means we've shown you all the members that match your preferences, or there are currently no eligible members in your area.",
                ],
            },
            {
                question: 'How can I match with someone?',
                answer: [
                    'You can match with someone in two ways.',
                    'break',
                    "First, you can match with someone if you've liked a member's profile and that member replies.",
                    'break',
                    "Secondly, you can match with someone if a member sends you a Like and you reply to their message.",
                ],
            },
            {
                question: 'How can I unmatch with someone?',
                answer: [
                    'To unmatch with someone, select your conversation in the Matches tab and tap on the more options icon (3 dots) in the top right corner of the screen. Then select Unmatch. If you are unmatching because they are vulgar, abusive, or making you feel uncomfortable, please select Block and Report and our team will investigate the matter.',
                ],
            },
            {
                question: 'How can I send a voice message?',
                answer: [
                    `To send a voice message, tap on the Microphone icon on the left of the chat input and tap and hold "Hold to record" to start recording. When you're done, swipe your finger to the Send button and release to send. You can also swipe to the Delete button to start over. If you want to listen to the voice message before sending, simply let go and click play.`,
                ],
            },
            {
                question: 'How does Bloom curate my Discover profiles?',
                answer: [
                    'Bloom uses a combination of your preferences and answers to the compatibility questionnaire to determine compatibility. It is important for us to know get to know all of our members well in order to be a good matchmaker.',
                ],
            },
            {
                question: 'How can I get more likes?',
                answer: ['Currently, all users can send 1 Like a day. We believe limited Likes can help members be more intentional with who they reach out to.'],
            },
        
        ],
    },
    {
        sectionHeader: 'Account',
        questions: [
            {
                question: 'How can I edit my profile?',
                answer: [
                    "To edit your profile, go to the Profile tab of your navigation bar. Select My Profile from the menu, and toggle to Edit. On this screen, you'll have the option to edit your photos, intro, Topics, and Basic Information. Make sure to click save when you're done editing!",
                ],
            },
            {
                question: 'How can I update my photos?',
                answer: [
                    "To update your profile photo, go to your Profile tab > My profile > Edit and tap on any photo you wish to replace or edit. Note that the first photo will be your default primary photo.",
                ],
            },
            {
                question: 'How can I update my email?',
                answer: [
                    `Go to your Profile tab > Account settings > Email. Tap "Change email" and follow the instructions on the app.`,
                ],
            },
            {
                question: 'How can I update my phone number?',
                answer: [
                    `Go to your Profile tab > Account settings > Phone number. Tap "Change number" and follow the instructions on the app.`,
                ],
            },
            {
                question: 'How can I log out?',
                answer: [
                    "To log out, tap on your Profile tab > Account Settings > Log out.",
                ],
            },
            {
                question: 'How can I delete my account?',
                answer: [
                    'Sorry to see you go! To delete your account, tap on your Profile tab > Account Settings > Delete account.',
                ],
            },   
        ],
    },
    {
        sectionHeader: 'Troubleshooting',
        questions: [
            {
                question: 'Why is Bloom only available in certain cities?',
                answer: [
                    "Our goal is to help everyone around the world find love, but in order to do so we must really focus on doing it right. To do that, we decided to focus on a few select cities and refine our methods and experience before bringing on more members. It is critical to us that we're focused on the quality of members and the relationships on the platform rather than the quantity of users.",
                ],
            },
            {
                question: 'Will a profile show up on my Discover tab more than once?',
                answer: [
                    "It is likely that the profile will show up again but no guarantees. Make sure before sending a Like or saying no that you really think about it.",
                ],
            },
            {
                question: 'Why are there no profiles in my Discover tab?',
                answer: [
                    "If you don't see any profiles on your Discover tab, it either means we've shown you all the members that match your preferences, or there are currently no eligible members in your area. Try widening your preferences to discover more profiles.",
                ],
            },
            {
                question: 'Why do I see the same profiles show up in my Discover tab?',
                answer: [
                    "Sometimes if we find a member that is quite compatible with your profile, we will show them to you again. Note that you are not required to send them a Like, and we're always refining our methods of compatibility matching.",
                ],
            },
            {
                question: 'Why am I not getting any Likes or Matches?',
                answer: [
                    "The way we have designed Bloom, the exlusiveness of the platform, and limiting the user to be able to only send one Like per day means that unlike the typical dating apps out there you won't be seeing as much Likes on your profile. We're focused on the quality of matches so when you do get that Like, it is significantly more meaningful.",
                ],
            },
        ],
    },
    {
        sectionHeader: 'Safety',
        questions: [
            {
                question: 'Will my last name be visible on my profile?',
                answer: [
                    'No. We only show your first name for privacy reasons. Your last name will always be hidden.',
                ],
            },
            {
                question: 'What should I do if someone is making me feel uncomfortable on the app?',
                answer: [
                    'Your safety means a lot to us. To unmatch with someone, select your conversation in the Matches tab and tap on the more options icon (3 dots) in the top right corner of the screen. Then select Unmatch. If you are unmatching because they are vulgar, abusive, or making you feel uncomfortable, please select Block and Report and our team will investigate the matter.',
                ],
            },
            {
                question: "How can I report someone I've matched with?",
                answer: [
                    `To report someone you've matched with, select your conversation in the Matches tab and tap on the more options icon (3 dots) in the top right corner of the screen. Select "Block and Report" and our team will investigate the matter.`,
                ],
            },
        ],
    },
];

class Blog extends React.Component {
    state = {
        selectedQuestion: '',
        answer: [],
    };

    setSelectedQuestion = (question, answer) => {
        this.setState({
            selectedQuestion: question,
            answer,
        });
    };

    renderSelectedQuestionView = () => {
        const { answer, selectedQuestion } = this.state;
        return (
            <div className="selected-question-container">
                <div>
                    <div style={{
                        cursor: 'pointer',
                    }} onClick={() => this.setSelectedQuestion('', [])}>
                        <p style={{
                            color: '#36633A',
                            fontSize: '18px',
                            fontWeight: '700',
                            marginBottom: '36px',
                        }}>All questions</p>
                    </div>

                    <p style={{
                        fontWeight: '800',
                        fontSize: '24px',
                        marginBottom: '24px',
                    }}>{selectedQuestion}</p>

                    {
                        answer.map(answerText => {
                            if (answerText === "break") {
                                return (
                                    <br />
                                )
                            } else {
                                return (
                                    <p style={{
                                        margin: 0,
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: 'rgba(26, 26, 26, 0.8)',
                                    }}>{answerText}</p>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }

    renderQuestionsView = () => {
        return (
            <div className="content-container">
                {
                    supportQuestions.map(supportQuestion => {
                        const { sectionHeader, questions } = supportQuestion;
                        return (
                            <div className="support-question-container">
                                <p style={{ fontWeight: '800', marginBottom: 24 }}>{sectionHeader}</p>
                                {
                                    questions.map(currentQuestion => {
                                        const { question, answer } = currentQuestion;
                                        return (
                                            <div style={{
                                                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                                            }}>
                                                <div style={{
                                                    color: '#36633A',
                                                    paddingTop: '20px',
                                                    paddingBottom: '20px',
                                                    cursor: 'pointer',
                                                }} onClick={() => {
                                                    this.setSelectedQuestion(question, answer)
                                                    }}>
                                                    {question}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    };

    render() {
        const { selectedQuestion } = this.state;

        return (
            <div className="Support">
                <p className="title">Support</p>
                {
                    selectedQuestion === '' ? this.renderQuestionsView() : this.renderSelectedQuestionView()
                }
            </div>
        );
    };
};

export default Blog;
