import React, { useState } from 'react';

import classNames from 'classnames';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import Confetti from 'react-confetti';

import { UilFacebook } from '@iconscout/react-unicons';
import { UilInstagram } from '@iconscout/react-unicons';
import { UilMediumM } from '@iconscout/react-unicons';

import {ReactComponent as AppstoreButton} from './appstore_button.svg';

import useWindowSize from '../hooks/useWindowSize';
import './styles/EmailForm.scss';

type Props = {
    backgroundColor: ?string,
}

export const EmailForm = ({
    backgroundColor = '#F7F7F8',
}: Props) => {
    const [ emailText, setEmailText ] = useState('');
    const [ isErrorShown, setIsErrorShown ] = useState(false);
    const [ isSuccessShown, setIsSuccessShown ] = useState(false);
    const { width } = useWindowSize();
    const [isAndroidWaitlistClicked, setIsAndroidWaitlistClicked] = useState(false)

    // Config variables
    const { REACT_APP_SPREADSHEET_ID, REACT_APP_SHEET_ID, REACT_APP_GOOGLE_CLIENT_EMAIL, REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY } = process.env;

    const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
        
        try {
          await doc.useServiceAccountAuth({
            client_email: REACT_APP_GOOGLE_CLIENT_EMAIL,
            private_key: REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY.replace(/\\n/g, '\n'),
          });
          // loads document properties and worksheets
          await doc.loadInfo();
      
          const sheet = doc.sheetsById[REACT_APP_SHEET_ID];
          await sheet.addRow(row);
          setIsErrorShown(false);
          setIsSuccessShown(true);
        } catch (e) {
          console.error('Error: ', e);
        }
      };
    
      function emailIsValid (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      }
      
      const handleButtonClick = () => {
        setIsSuccessShown(false);
    
        if (!emailIsValid(emailText)) {
          setIsErrorShown(true);
        } else {
          setIsErrorShown(false);
          const newRow = { Email: emailText };
          appendSpreadsheet(newRow);
        }
    }

    const renderForm = () => {
        if (!isAndroidWaitlistClicked) {
            return (
                <div className="EmailForm-actionItems">
                    <a 
                    target="_blank" rel="noreferrer"
                    href="https://apps.apple.com/us/app/bloom-the-modern-matchmaker/id1611823656">
                    <AppstoreButton className="appstore-button" style={{
                        width: '152px',
                        height: '50px',
                    }}/></a>
                    <button className="EmailForm-submitButton font-face-satoshi-bold" onClick={() => setIsAndroidWaitlistClicked(true)}>
                        Join Android Waitlist
                    </button>
                </div>
            
            )
        }

        return (
            <div className="EmailForm-actionItems">
                <div className="EmailForm-emailContainer">
                    <input
                        className={classNames("EmailForm-emailInput font-face-satoshi-regular", { 'is-error': isErrorShown })}
                        placeholder="Email"
                        onChange={event => {
                        setEmailText(event.target.value);
                        setIsErrorShown(false);
                        }}
                        style={{
                            background: backgroundColor,
                        }}
                    />
                    {isErrorShown && <p className="EmailForm-error">So close! Please enter a valid email</p>}
                </div>
                <button className="EmailForm-submitButton font-face-satoshi-bold" onClick={handleButtonClick}>
                    Join Waitlist
                </button>
            </div>
        );
    };

    const renderSuccessMessage = () => {
        return (
            <div className="EmailForm-successMessage">
                <span>🎉 Congrats, you’re on the waitlist! Have a second? </span>
                <a 
                    href="https://docs.google.com/forms/d/1BktKeJYWlL-SIv8DCZpfF143CwKRYDOe9pi5pyPTI5Y/edit"
                    target="_blank" rel="noreferrer"
                >Answer a few questions</a>
                <span> to help us shape the app for you and follow our journey at @lovebybloom.</span>

                <div className="links">
                    <a
                        className="link" href="https://www.instagram.com/lovebybloom"
                    >
                        <UilInstagram height={24} width={24} color={'black'} />
                    </a>
                    <a
                        className="link" href="https://lovebybloom.medium.com"
                    >
                        <UilMediumM height={24} width={24} color={'black'} />
                    </a>
                    <a
                        className="link" href="https://www.facebook.com/lovebybloom"
                    >
                        <UilFacebook height={24} width={24} color={'black'} />
                    </a>
                </div>
            </div>
        );
    };

    return (
        <div>
            {
                isSuccessShown ? renderSuccessMessage() : renderForm()
            }
            {
                isSuccessShown &&
                <Confetti
                    height={
                        document.body.scrollHeight > window.innerHeight
                            ? document.body.scrollHeight
                            : window.innerHeight
                    }
                    numberOfPieces={500}
                    recycle={false}
                    width={width}
                />
            }
        </div>
    );
};

