import './styles/MatchmakerVerifiedBadge.scss'

function MatchmakerVerifiedBadge() {
    return (
        <div style={{
            width: 149,
            height: 26,
            backgroundColor: '#4B9B53',
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className="matchmakerVerifiedIcon" style={{ marginRight: 8 }}/>
            <p style={{
                fontSize: 12,
                fontWeight: 700,
                color: 'white',
            }}>Matchmaker Verified</p>
        </div>
    )
}

export default MatchmakerVerifiedBadge