import { BehaviorSubject } from 'rxjs';
import { initializeApp } from "@firebase/app";
import { getAuth, FacebookAuthProvider, signInWithPopup } from "@firebase/auth";
import { serverTimestamp, getFirestore, doc, setDoc, getDoc } from "@firebase/firestore";
import history from '../helpers/history';

import axios from 'axios';
import { Configuration, OpenAIApi } from "openai";

const firebaseConfig = {
    apiKey: "AIzaSyARoRtLbwpnY2XmGzYHL00hk0aBVfPkfdc",
    authDomain: "virality-7d524.firebaseapp.com",
    projectId: "virality-7d524",
    storageBucket: "virality-7d524.appspot.com",
    messagingSenderId: "422830094580",
    appId: "1:422830094580:web:6c8063cf73aa50143186a1",
    measurementId: "G-3LYQ6YXK13"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const openAIconfiguration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
});
const openai = new OpenAIApi(openAIconfiguration);

const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;
const accountSubject = new BehaviorSubject({
    isLoggedIn: false,
    isLoading: false,
});
const provider = new FacebookAuthProvider();
provider.addScope('email');
provider.addScope('public_profile');
provider.addScope('user_birthday');
provider.addScope('user_hometown');
provider.addScope('user_likes');
provider.addScope('user_photos');
provider.addScope('user_hometown');
provider.addScope('user_gender');


export const accountService = {
    login,
    logout,
    getAll,
    getById,
    update,
    account: accountSubject.asObservable(),
    get accountValue () { return accountSubject.value; }
};
const auth = getAuth(app);
const db = getFirestore(app);

async function login() {
    var firebaseFBAuth = await signInWithPopup(auth, provider);

    function getFacebookData(resolve, reject) {
        window.FB.api(
            "/me?fields=birthday,first_name,hometown,quotes,gender,likes.limit(10){name, link, picture},picture.height(720).width(720),relationship_status,photos.limit(10){link,name_tags,images}",
            {
                access_token: firebaseFBAuth._tokenResponse.oauthAccessToken,
            },
            function (response) {
              if (response.error) {
                reject({})
              }
              accountSubject.next({
                isLoggedIn: true,
                isLoading: true,
              });
              resolve(response);
            }
        );
    }

    function getPrompt(promptType, profileData) {
        const pronouns = (profileData.gender === 'male') ? ['He','his'] : ['She','her'];
        const { likes } = profileData;
        var prompt = `Write me a ${promptType} for ${profileData.firstName}.`;
        if (profileData.hometown) {
            prompt = `${prompt} ${pronouns[0]} is from ${profileData.hometown}.`; 
        }
        if (profileData.quotes) {
            prompt = `${prompt} Here are ${pronouns[1]} favorite quotes: \n${profileData.quotes}.`;
        }
        if (likes) {
            let likesFormatted = likes.map(like => like.name).join(',');
            const output = `${prompt} Here are 10 Facebook Pages that ${pronouns[1]} likes: \n${likesFormatted}.`;
            prompt = output;
        }
        return prompt;
    };

    var profileData;
    profileData = await getById(auth.currentUser.uid);
    if (profileData !== undefined) {
        console.log(profileData);
        accountSubject.next({
            ...profileData,
            isLoading: false,
        });
        return;
    }

    const response = await new Promise(getFacebookData);
    const likes = response.likes.data.map((like) => {
        return {name: like.name, photo: like.picture.data.url, link: like.link}
    });
    const {
        birthday,
        hometown,
        first_name,
        gender,
        quotes,
        picture,
        photos
    } = response;
    profileData = {
        isLoggedIn: true,
        firstName: first_name,
        birthday,
        gender,
        quotes: quotes.replace('\n',''),
        hometown: hometown.name,
        likes,
        profilePicture: picture.data.url,
        photos: photos.data.map(photo => photo.images[0])
    };
    let {data} = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: getPrompt('dating profile', profileData),
        max_tokens: 1200,
        temperature: 1,
    });
    profileData.datingProfilePrompt = data.choices[0].text;
    const results = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: getPrompt('two sentence introduction', profileData),
        temperature: 1,
        max_tokens: 300,
    });
    profileData.introductionPrompt = results.data.choices[0].text;
    await update(auth.currentUser.uid, profileData);
    accountSubject.next({
        ...profileData,
        isLoading: false,
    });
    const { from } = history.location.state || { from: { pathname: `/signup?id=${auth.currentUser.uid}`,  } };
    history.push(from);
}

function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
    accountSubject.next(null);
}

function getAll() {
    return axios.get(baseUrl)
        .then(response => response.data);
}

async function getById(id) {
    const snapshot =  await getDoc(doc(db, "autoGeneratedProfiles", id));
    return snapshot.data();
}

async function update(id, profileData) {
    await setDoc(doc(db, "autoGeneratedProfiles", id), {
        ...profileData,
        createdAt: serverTimestamp()
    });
}