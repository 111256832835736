import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom';

import BlogPostCards from '../components/BlogPostCards';
import { EmailForm } from '../components/EmailForm';

import { posts } from '../posts/postData';
import 'typeface-overpass';
import './styles/Home.scss';

function Home({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
         // eslint-disable-next-line
      }, []);

    return (
        <div className="Home">
            <div className="Home-avatar-one" />
            <div className="Home-avatar-two" />
            <div className="Home-avatar-three" />
            <div className="Home-avatar-four" />
            <div className="Home-avatar-five" />
            <div className="Home-avatar-six" />
            <div className="Home-mainContent">
                <p className="Home-mainTitlePartOne font-face-satoshi-bold">Your modern matchmaker for serious relationships</p>
                <p className="Home-mainTitlePartTwo">Sign up for early access and updates.</p>
                <EmailForm />
            </div>

            <div className="Home-section-one">
                <div className="container">
                    <div className="discover-screen-graphic" />
                    <div className="Home-section-one-info">
                        <p className="Home-section-one-info-title font-face-satoshi-bold">
                            Built for the long-term
                        </p>

                        <p className="Home-section-one-info-subtitle font-face-satoshi-light">
                            Bloom is built for serious relationship seekers. Join a community of members who are focused on quality connections, not swiping.
                        </p>
                    </div>
                </div>
            </div>

            <div className="Home-sections">
                <div className="Home-section-two">
                    <p className="header font-face-satoshi-bold">
                        Get daily curated profiles based on what matters
                    </p>
                    <p className="subtitle font-face-satoshi-light">
                        Bloom takes the time to get to know you and your needs to suggest quality matches.
                    </p>
                        <div className="curated-profiles-graphic" />
                </div>

                <div className="Home-section-three">
                    <p className="header font-face-satoshi-bold">
                        Go beyond the surface with meaningful profiles
                    </p>
                    <p className="subtitle font-face-satoshi-light">
                        Every profile is a unique story, and Bloom will help you tell that authentically.
                    </p>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <div className="meaningful-profiles-graphic" />
                    </div>
                </div>
            </div>

            <div className="Home-section-four">
                <div className="container">
                    <div className="info">
                        <p className="header font-face-satoshi-bold">
                            Send voice messages to connect authentically
                        </p>
                        <p className="subtitle font-face-satoshi-regular">
                        Your voice is unique and personal. Send sound bytes to add an extra layer of character and authenticity.
                        </p>
                    </div>
                    <div className="graphic" />
                </div>
            </div>


            <div className="Home-bloom-team-help">
                <div className="graphic" />
                <p className="title font-face-satoshi-bold">The Bloom Team is always available via chat to give you guidance</p>
                <p className="subtitle font-face-satoshi-regular">
                    Whether you need help creating a strong profile or connecting with matches, the team is always hands on deck.
                </p>
            </div>

            <div className="Home-blog-container" id="blog">
                <p className="header">Blog</p>
                <div className="Home-blog">
                    <BlogPostCards posts={posts} />
                </div> 
            </div>

            <div className="Home-section-five">
                <div className="container">
                    <div className="graphic" />

                    <div className="email-section">
                        <p className="header font-face-satoshi-bold text">Start looking for your life-long partner.</p>
                        <EmailForm backgroundColor="white" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Home);