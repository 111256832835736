export const dummyProfile = {
    Id: 123,
    Age: 0.6,
    FirstName: 'Yuki',
    isMatchmakerVerified: true,
    Intro: `
    Yuki is an adventurous and curious bundle of joy who appreciates gentle yet spontaneous belly rubs. When he is not bathing by the window in the warm afternoon sun, he loves finding the highest furniture to mount while keeping an eye on his neighborhood. 
    `,
    Location: 'East Palo Alto, CA',
    Title: 'Furniture Scratcher',
    IndustryField: 'Hospitality',
    EducationLevel: 'Undergraduate',
    School: 'University of Meow',
    Ethnicities: ['East Asian'],
    Height: `1'1`,
    Religions: ['Christian'],
    RelationshipStatus: 'Never married',
    HasKids: `Doesn't have kids`,
    WantKids: `Doesn't want kids`,
    AlcoholUsage: 'Drinks occasionally',
    CigaretteUsage: 'Never smokes',
    DrugUsage: 'Never uses drugs',
    MarijuanaUsage: 'Never uses marijuana',
    PoliticalViews: 'Moderate',
    Interests: [
        'Climbing',
        'Scratching',
        'Annoying my owners',
    ],
    Characteristics: [
        'Loyal',
        'Cuddly',
        'Cute',
    ],
    Photos: [
        {"remoteUri":"https://i.ibb.co/CVQ7g1g/315529989-1105647243451162-5639591412090277318-n.jpg"},
        {"remoteUri": "https://i.ibb.co/60x04bj/309243845-5473423226068513-7484517143495551261-n.jpg"},
        {"remoteUri":"https://i.ibb.co/p48MGz4/315519392-427392506276255-8502420256196796873-n.jpg"},
        {"remoteUri":"https://i.ibb.co/PwCZ2Jj/315492532-712896939745505-1438347303655725916-n.jpg"},
        {"remoteUri":"https://i.ibb.co/tLYfyv2/315527151-1194881058075879-1079934529681852540-n.jpg"},
    ],
    Topics: [
        {
            title: `Interest and hobbies`,
            description: `
            From indoor climbing to chasing small tinfoil balls around the house, Yuki spends a significant amount of his time being physically active. his favorite past-time is finding new crawl spaces to take quick naps in and following his human roommates to the restroom to ensure that they are safe from any outside threats. Yuki is obsessed with Amazon boxes and appreciates it when he sees them laying on the floor because every now-and-then he likes scratching them to train his claws.
            `,
        }, {
            title: `Lifestyle`,
            description: `
                Best defined as an extroverted introvert, Yuki lives a very-much laid back lifestyle where his daily routine and meals are kept at a predictable schedule. With that said, he loves spontaneity and absolutely goes wild when his human roommates bring out the laser and stick toys. 
                \n
                When Yuki is done with his day, he likes rubbing his body against his human roommates and getting a solid petting session going. Gentle scratches behind the ears and on his belly is his love language.
            `
        }, {
            title: `Career`,
            description: `Currently, Yuki is working as a Furniture Scratcher full-time and a part-time security guard at his neighborhood watch. He loves the job and sees a lot of career growth in the position - and absolutely loves the fact that it is remote and he can work from home. `,
        }, {
            title: 'Preferences',
            description: `
            Yuki is looking for another amazing feline who appreciates honesty, adventure, and growth as much as he does. For Yuki, his future partner-in-crime must be willing to follow his on adventures big and small, as well as be totally happy cuddling on the couch behind the cushions on a Friday night. 
            \n
            Preferably, the individual is not dog-friendly but would be willing to accept the woof if they show consistency and self-control. They must be willing to be playful and enthusiastic on similar hobbies like pushing things off the edge of the table but also be open to climbing to the highest book cabinet the humans can find - whenever the time calls for it. 
            \n            
            For Yuki, he wants a feline who can look past his gorgeous fur and captivating eyes. He wants his feline-in-crime to be someone who genuinely loves him and will split their tasty tuna can with - that would be pawfect. 
            `
        }
    ]
}

const characteristicTagOptions = [
    'Active',
    'Adventurous ',
    'Affectionate',
    'Calm',
    'Charming',
    'Chill',
    'Compassionate',
    'Confident',
    'Creative',
    'Curious',
    'Daring',
    'Decisive ',
    'Determined',
    'Diligent',
    'Disciplined',
    'Enthusiastic ',
    'Faithful',
    'Flexible',
    'Focused',
    'Friendly',
    'Funny',
    'Gentle',
    'Genuine',
    'Happy',
    'Hardworking',
    'Humble',
    'Idealistic',
    'Imaginative',
    'Independent ',
    'Kind',
    'Loyal',
    'Modest',
    'Open-minded',
    'Optimistic',
    'Organized',
    'Passionate',
    'Patient',
    'Playful',
    'Practical',
    'Proud',
    'Quiet',
    'Quirky',
    'Rational',
    'Reliable',
    'Romantic',
    'Sensitive',
    'Shy',
    'Sociable',
    'Spontaneous ',
    'Straightforward',
    'Sweet',
    'Thoughtful',
    'Witty'
  ];
  
  const interestTagOptions = [
    'Anime',
    'Archery',
    'Art',
    'Astrology',
    'Astronomy',
    'Backpacking',
    'Badminton',
    'Baking',
    'Ballet',
    'Ballroom dancing',
    'Baseball',
    'Basketball',
    'Beer tasting',
    'Billiards',
    'Birdwatching',
    'Blogging',
    'Board games',
    'Bodybuilding',
    'Bowling',
    'Boxing',
    'Breadmaking',
    'Breakdancing',
    'Business',
    'Calligraphy',
    'Camping',
    'Canoeing',
    'Chess',
    'Computers',
    'Concerts',
    'Cooking',
    'Cosplaying',
    'Crafts',
    'Crossfit',
    'Curling',
    'Cycling',
    'Dancing',
    'Debate',
    'Design',
    'Diving',
    'DJing',
    'Entrepreneurship',
    'Farming',
    'Fashion',
    'Fencing',
    'Figure skating',
    'Filmmaking',
    'Fishing',
    'Fitness',
    'Furniture building',
    'Gardening',
    'Golfing',
    'Guitar',
    'Gymnastics',
    'Hiking',
    'Hockey',
    'Home improvement',
    'Horseback riding',
    'Hunting',
    'Journaling',
    'Juggling',
    'Karaoke',
    'Kayaking',
    'Kitesurfing',
    'Knitting',
    'Learning',
    'Magic',
    'Manga',
    'Martial arts',
    'Mathematics',
    'Meditation',
    'Metalworking',
    'Minimalism',
    'Movies & TV',
    'Music',
    'Paintball',
    'Painting',
    'Paragliding',
    'Parkour',
    'Pets',
    'Philosophy',
    'Photography',
    'Piano',
    'Pilates',
    'Plant care',
    'Playing instruments',
    'Poetry',
    'Pottery',
    'Public speaking',
    'Puzzles',
    'Reading',
    'Rock climbing',
    'Rugby',
    'Running',
    'Sailing',
    'Science',
    'Scuba diving',
    'Shopping',
    'Singing',
    'Skateboarding',
    'Skating',
    'Skiing',
    'Skydiving',
    'Snowboarding',
    'Soccer',
    'Social media',
    'Speedcubing',
    'Surfing',
    'Swimming',
    'Table tennis',
    'Tennis',
    'Thrifting',
    'Travelling',
    'Video games',
    'Volleyball',
    'Volunteering',
    'Weightlifting',
    'Wine tasting',
    'Woodworking',
    'Working out',
    'Writing',
    'Yoga'
  ];

  export {
    interestTagOptions,
    characteristicTagOptions,
  }