import { useEffect, useState } from "react"
import { accountService } from "../services";
import { UilLocationPoint } from '@iconscout/react-unicons'
import { useSearchParams } from "react-router-dom";
import './styles/Profile.scss';


function SignUp() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userProfile, setUserProfile] = useState({});


    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id");
        if (id !== null) {
            accountService.getById(id).then((data) => {
                setUserProfile(data);
            });
            return;
        }

        const subscription = accountService.account.subscribe((profile) => {
            if (profile !== null && profile !== undefined && profile !== {}) {
                setIsLoggedIn(profile.isLoggedIn);
                setIsLoading(profile.isLoading);
                const newProfile = {...profile};
                setUserProfile(newProfile);
                const {likes, photos} = profile;
                if (likes) {
                    newProfile.likes = [...likes];
                } else {
                    newProfile.likes = [];
                }
                if (photos) {
                    newProfile.photos = [...photos];
                } else {
                    newProfile.photos = [];
                }
                setUserProfile(newProfile);
            }
        });
    
        return () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        };
      }, []);

    function renderLoginButton() {
        const onLogin = () => {
            setIsLoading(true);
            accountService.login();
        }
        return (
            <div style={{
                marginTop: '100px',
            }}>
                <p onClick={onLogin}>Login using facebook</p>
            </div>
        )
    }
    
    function onLoginStateChange(response) {
       setIsLoggedIn(response.status === 'connected');
       console.log('statusChanged', response.status === 'connected');
    }
    window.FB.Event.subscribe('auth.statusChange', onLoginStateChange)

    if (!isLoggedIn) {
        return renderLoginButton();
    }

    if (isLoading || userProfile==={}) {
        return (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <div className="loadingIcon" style={{
                    marginTop: 100,
                }}/>
                <span>Creating your profile</span>
            </div>
        )
    }
    return (
        <div className="Profile" style={{
            marginTop: 100,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '24px',
            paddingRight: '24px',
        }}>
               <img 
                    src={userProfile.profilePicture}
                    alt="new"
                    style={{
                        borderRadius: 8,
                        width: '100%',
                        height: 359,
                        objectFit: 'cover',
                    }}
                />
                
                <p style={{
                    fontSize: 28,
                    fontWeight: 700,
                    marginBottom: 12,
                    marginTop: 24,
                }}>{userProfile.firstName}</p>
                            
                <p style={{
                    marginTop: 24,
                    fontSize: 16,
                    color: 'rgba(18, 17, 32, 0.7)',
                    fontWeight: 450,
                    width: '100%',
                    marginBottom: 24,
                }}>
                    {userProfile.introductionPrompt}
                </p>
                
                <div className="basicInfoItem">
                    <UilLocationPoint size={18} color="black" />
                    <p className="text">{userProfile.hometown}</p>
                </div>

                <div className="interests">
                    <p style={{
                        fontSize: 16,
                        fontWeight: 700,
                    }}>I'm interested in</p>
                   
                        {userProfile.likes.map(interest => {
                            return (
                            <div className="blurbContainer">
                                <img
                                    src={interest.photo}
                                    alt="new"
                                    style={{
                                        borderRadius: 8,
                                        width: '100%',
                                        height: 50,
                                        width: 50,
                                        objectFit: 'cover',
                                    }}
                                />
                                <a href={interest.link}>{interest.name}</a>
                            </div>
                            )
                        })}
                </div>

                <div className="characteristics">
                    <p style={{
                        fontSize: 16,
                        fontWeight: 700,
                    }}>My favorite Quotes</p>
                    <div className="blurbContainer">
                        {userProfile.quotes}
                    </div>
                </div>

                {userProfile.photos.length > 0 && (
                    <img 
                        src={userProfile.photos[0].source}
                        alt="new"
                        style={{
                            borderRadius: 8,
                            width: '100%',
                            height: 359,
                            objectFit: 'cover',
                        }}
                    />)
                }

                <div className="topics">
                    <p className="title" style={{
                        whiteSpace: 'pre-line'
                    }}>More about Me</p>
                    <p>{userProfile.datingProfilePrompt}</p>
                </div>

                <p style={{
                    fontSize: 24,
                    fontWeight: 700,
                    textAlign: 'center',
                    paddingLeft: 24,
                    paddingRight: 24,
                }}>
                    Like this profile? Download Bloom to match with them!
                </p>

                {userProfile.photos.length > 1 && (
                    userProfile.photos.slice(1).map(
                        (photo) => {
                            return (
                                <img 
                                    src={photo.source}
                                    alt="new"
                                    style={{
                                        borderRadius: 8,
                                        width: '100%',
                                        height: 359,
                                        objectFit: 'cover',
                                    }}
                                />
                            );
                        })
                    )
                }
            </div>
    )
}

export default SignUp