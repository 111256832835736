import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Home from './pages/Home';
import Profile from './pages/Profile';
import Blog from './pages/Blog';
import Support from './pages/Support';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Spotlight from './pages/Spotlight';
import Signup from './pages/Signup';
import 'typeface-overpass';
import './App.scss';
import history from './helpers/history';

// TODO: spotlight link is disabled for now until we have 3 real spotlighted users, for now there is no value add
function App() {
  return (
    <Router history={history}>
        <div className="header">
            <nav className="nav">
                <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <div className="bloom-logo" />
                    <Link className="link font-face-satoshi-bold" to="/" style={{ fontSize: '24px', margin: 0 }}>Bloom</Link>
                </div>
                <HashLink className="hover-underline-animation-black link font-face-satoshi-bold" style={{ color: 'black' }} to="/#blog">Blog</HashLink>
                <Link className="hover-underline-animation-black link last font-face-satoshi-bold" to="/support" style={{ color: 'black' }}>Support</Link>
                {/* <Link className="hover-underline-animation-black link last font-face-satoshi-bold" to="/spotlight" style={{ color: 'black' }}>Spotlight</Link> */}
                <Link className="hover-underline-animation-green link last font-face-satoshi-bold" to="/sign-up" style={{ color: '#4B9B53' }}>Signup</Link>
            </nav>
        </div>

        <Switch>
            <Route path="/profile" component={Profile} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/support" component={Support} />
            <Route path="/blog" component={Blog} />
            <Route path="/spotlight" component={Spotlight} />
            <Route path="/signup" component={Signup}/>
            <Route path="/">
                <Home />
            </Route>
        </Switch>

        <div className="footer">
            <div className="primary-links">
                <p className="copyright-text" style={{
                    color: 'rgba(26, 26, 26, 0.8)',
                }}>
                    © 2022 The Bloom Company
                </p>
            </div>
            <div className="footer-links">
                <div className="social-links">
                    <p className="font-face-satoshi-bold">Social</p>
                    <a
                        className="link font-face-satoshi-regular" href="https://www.instagram.com/lovebybloom"
                    >
                        Instagram
                    </a>
                    <a
                        className="link font-face-satoshi-regular" href="https://lovebybloom.medium.com"
                    >
                        Medium
                    </a>
                    <a
                        className="link font-face-satoshi-regular" href="https://www.facebook.com/lovebybloom"
                    >
                        Facebook
                    </a>
                </div>
                <div className="legal-links">
                    <p className="font-face-satoshi-bold">Legal</p>
                    <Link className="link font-face-satoshi-regular" to="/privacy"
                    >Privacy</Link>
                    <Link className="link font-face-satoshi-regular" to="/terms" >Terms</Link>
                </div>

            </div>
        </div>
    </Router>
  );
}

export default App;
