import { useEffect, useState } from 'react';
import { dummyProfile } from '../constants';

import MatchmakerVerifiedBadge from '../components/MatchmakerVerifiedBadge';

import './styles/Spotlight.scss';

const API_URL = 'https://us-central1-testing-3c3b0.cloudfunctions.net/publicProfiles-getProfileData?';
const DEFAULT_PROFILES = [dummyProfile, dummyProfile, dummyProfile];
function Spotlight() {
    const [spotlightUsers, setSpotlightUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = new URLSearchParams(window.location.search);
    const userIds = [params.get('a'), params.get('b'), params.get('c')];

    const getProfile = (id) => {
        if (id === null) {
            return Promise.resolve(null);
        }
        return fetch(API_URL + new URLSearchParams({
            plt: id,
        })).then((response) => {
            return response.json();
        }).catch(() => {
            return Promise.resolve(null);
        });
    }

    useEffect(() => {
        if (userIds.filter((userId) => userId !== null).length === 0) {
            setIsLoading(false);
            setSpotlightUsers(DEFAULT_PROFILES);
            return;
        }
        Promise.all(userIds.map(getProfile)).then((results) => {
            const nonNullResults = results.filter((profile) => profile !== null);
            if (nonNullResults.length > 0) {
                setSpotlightUsers(nonNullResults);
                setIsLoading(false);
                return;
            }
            setSpotlightUsers(DEFAULT_PROFILES);
            setIsLoading(false);
        });
         // eslint-disable-next-line
    }, []);

    if (isLoading) {
        return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div className="loadingIcon" style={{
                marginTop: 100,
            }}/>
        </div>
    }

    return (
        <div className="Spotlight">
            <p style={{
                fontSize: 24,
                fontWeight: 700,
                textAlign: 'center',
                marginBottom: 24,
            }}>
                Here are this week's spotlighted users:
            </p>
            <div className="carousel">
                {spotlightUsers.map(spotlightUser => {
                    return (
                        <a href={`/profile?userId=${spotlightUser.Id}`} style={{ textDecoration: 'none' }}>
                        <div className="card" style={{
                            backgroundImage: `url(${spotlightUser.Photos[0].remoteUri})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer',
                        }}>
                            {spotlightUser.isMatchmakerVerified && <MatchmakerVerifiedBadge />}
                            <div>
                                <p style={{
                                    color: 'white',
                                    fontSize: 22,
                                    fontWeight: 700,
                                }}>{spotlightUser.FirstName}, {spotlightUser.Age}</p>
                                <p style={{
                                    color: 'white',
                                    fontSize: 14,
                                    fontWeight: 700,
                                }}>
                                    {spotlightUser.Title} · {spotlightUser.Location}
                                </p>
                                <p style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    maxHeight: '46px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    {spotlightUser.Intro}
                                </p>
                            </div>
                        </div>
                        </a>
                    )
                })}
            </div>
        </div>
    )
}

export default Spotlight