import { Link } from "react-router-dom";

import './styles/BlogPostCards.scss';

type Props = {
    posts: Array<any>,
};

export default function BlogPostCards({ posts }: Props) {
    if (posts.length === 0) {
        return null;
    } 

    let updatedPosts = posts;
    if (posts.length % 2 !== 0) {
        updatedPosts.push({
            imageUrl: '',
            publishDate: '',
            author: '',
            title: '',
            paragraphs: [{text: '', type: 'none'}],
        });
    }

    return (
        updatedPosts.map(post => {
            if (!post.author) {
                return (
                    <div className="BlogPostCard-empty">
                        <div className="BlogPostCard-empty-contents">
                            <div
                                className="BlogPostCard-empty-contents-image"
                                style={{ backgroundImage: post.imageUrl}}
                            />
                                <p className="title">{post.title}</p>
                                <p className="subtitle">{post.paragraphs[0].text}</p>
                        </div>
                    </div>
                );
            };

            return (
                <Link
                    className="BlogPostCard-link"
                    to={{
                        pathname: '/blog',
                        search: `?name=${post.path}`,
                        state: post,
                    }}
                >
                    <div className="BlogPostCard-contents">
                        <div
                            className="BlogPostCard-contents-image"
                            style={{ backgroundImage: post.imageUrl }}
                        />
                            <p className="title">{post.title}</p>
                            <p className="subtitle">{post.paragraphs[0].text}</p>
                    </div>
                </Link>
            );
        })
    );
};