import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { UilLocationPoint } from '@iconscout/react-unicons'
import { UilBriefcaseAlt } from '@iconscout/react-unicons'
import { UilGraduationCap } from '@iconscout/react-unicons'
import { UilGlobe } from '@iconscout/react-unicons'
import { UilBookOpen } from '@iconscout/react-unicons'
import { UilUsersAlt } from '@iconscout/react-unicons'
import { UilKid } from '@iconscout/react-unicons'
import { UilBabyCarriage } from '@iconscout/react-unicons'
import { UilGlassMartiniAlt } from '@iconscout/react-unicons'
import { UilCapsule } from '@iconscout/react-unicons'
import { UilUniversity } from '@iconscout/react-unicons'

import { EmailForm } from '../components/EmailForm';
import MatchmakerVerifiedBadge from '../components/MatchmakerVerifiedBadge';

import { dummyProfile } from '../constants';

import './styles/Profile.scss';

const API_URL = 'https://us-central1-testing-3c3b0.cloudfunctions.net/publicProfiles-getProfileData?'

function Profile({ location }) {
    const [userProfile, setUserProfile] = useState(null)
    const [isShowingDummyProfile, setIsShowingDummyProfile] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');

    // TODO: fetch the profile via the user id and then populate this page
    useEffect(() => {
        setIsLoading(true)
        fetch(API_URL + new URLSearchParams({
            plt: userId,
        })).then((response) => {
            return response.json();
        }).then((data) => {
            setUserProfile(data);
            setTimeout(() => {
                setIsLoading(false);
            }, 1500)
        }).catch(() => {
            setUserProfile(dummyProfile);
            setIsShowingDummyProfile(true);
            setTimeout(() => {
                setIsLoading(false)
            }, 1500)
        });
         // eslint-disable-next-line
    }, [])

    const renderMatchmakerBadge = () => {
        if (!userProfile.isMatchmakerVerified) {
            return null
        }
        return <MatchmakerVerifiedBadge />
    }

    if (isLoading || !userProfile) {
        return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div className="loadingIcon" style={{
                marginTop: 100,
            }}/>
        </div>
    }

    return (
        <div className="Profile" style={{
            marginTop: 100,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '24px',
            paddingRight: '24px',
        }}>
            {isShowingDummyProfile && <p style={{
                fontSize: 28,
                fontWeight: 700,
                marginBottom: 24,
            }}>
                Oops... looks like we couldn't find that person's profile. Here's another one that we have!
            </p>}
                <img 
                    src={userProfile?.Photos[0].remoteUri}
                    alt="new"
                    style={{
                        borderRadius: 8,
                        width: '100%',
                        height: 359,
                        objectFit: 'cover',
                    }}
                />
                
                <p style={{
                    fontSize: 28,
                    fontWeight: 700,
                    marginBottom: 12,
                    marginTop: 24,
                }}>{userProfile.FirstName}</p>
                
                {renderMatchmakerBadge()}
                
                <p style={{
                    marginTop: 24,
                    fontSize: 16,
                    color: 'rgba(18, 17, 32, 0.7)',
                    fontWeight: 450,
                    width: '100%',
                    marginBottom: 24,
                }}>
                    {userProfile.Intro}
                </p>
                
                <div className="basicInfoItem">
                    <UilLocationPoint size={18} color="black" />
                    <p className="text">{userProfile.Location}</p>
                </div>

                <div className="basicInfoItem">
                    <UilBriefcaseAlt size={18} color="black" />
                    <p className="text">{userProfile.Title}</p>
                </div>

                <div className="basicInfoItem">
                    <UilBriefcaseAlt size={18} color="black" />
                    <p className="text">{userProfile.IndustryField}</p>
                </div>

                <div className="basicInfoItem">
                    <UilGraduationCap size={18} color="black" />
                    <p className="text">{userProfile.EducationLevel}</p>
                </div>

                <div className="basicInfoItem">
                    <UilGlobe size={18} color="black" />
                    <p className="text">{userProfile.Ethnicities.join(',')}</p>
                </div>

                <div className="basicInfoItem">
                    <UilBookOpen size={18} color="black" />
                    <p className="text">{userProfile.Religions.join(',')}</p>
                </div>

                <div className="basicInfoItem">
                    <UilUsersAlt size={18} color="black" />
                    <p className="text">{userProfile.RelationshipStatus}</p>
                </div>

                <div className="basicInfoItem">
                    <UilKid size={18} color="black" />
                    <p className="text">{userProfile.HasKids}</p>
                </div>

                <div className="basicInfoItem">
                    <UilBabyCarriage size={18} color="black" />
                    <p className="text">{userProfile.WantKids}</p>
                </div>

                <div className="basicInfoItem">
                    <UilGlassMartiniAlt size={18} color="black" />
                    <p className="text">{userProfile.AlcoholUsage}</p>
                </div>

                <div className="basicInfoItem">
                    <div className="smokingIcon" />
                    <p className="text">{userProfile.CigaretteUsage}</p>
                </div>

                <div className="basicInfoItem">
                    <UilCapsule size={18} color="black" />
                    <p className="text">{userProfile.DrugUsage}</p>
                </div>

                <div className="basicInfoItem">
                    <div className="marijuanaIcon" />
                    <p className="text">{userProfile.MarijuanaUsage}</p>
                </div>

                <div className="basicInfoItem">
                    <UilUniversity size={18} color="black" />
                    <p className="text">{userProfile.PoliticalViews}</p>
                </div>

                <div className="interests">
                    <p style={{
                        fontSize: 16,
                        fontWeight: 700,
                    }}>I'm interested in</p>
                    <div className="blurbContainer">
                        {userProfile.Interests.slice(0, 3).map(interest => {
                            return (
                                <div className="blurb">
                                    <p>{interest}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="characteristics">
                    <p style={{
                        fontSize: 16,
                        fontWeight: 700,
                    }}>I'm known for</p>
                    <div className="blurbContainer">
                        {userProfile.Characteristics.slice(0, 3).map(characteristic => {
                            return (
                                <div className="blurb">
                                    <p>{characteristic}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <img 
                    src={userProfile?.Photos[1].remoteUri}
                    alt="new"
                    style={{
                        borderRadius: 8,
                        width: '100%',
                        height: 359,
                        objectFit: 'cover',
                        marginTop: 24,
                        marginBottom: 24,
                    }}
                />

                <div className="topics" style={{ marginBottom: '24px' }}>
                 {
                     userProfile.Topics.map(topic => {
                         return (
                             <div>
                                 <p className="title" style={{
                                     whiteSpace: 'pre-line'
                                 }}>
                                    {topic.title}
                                 </p>
                                 <p className="content" style={{
                                     whiteSpace: 'pre-line'
                                 }}>
                                    {topic.description}
                                 </p>
                            </div>
                         )
                     })
                 }
                </div>

                 {userProfile?.Photos.slice(2).map(photo => {
                    return (
                    <img 
                    src={photo.remoteUri}
                    alt="new"
                    style={{
                        borderRadius: 8,
                        width: '100%',
                        height: 359,
                        objectFit: 'cover',
                        marginBottom: 24,
                    }}
                />
                        )
                    })}

            <p style={{
                fontSize: 24,
                fontWeight: 700,
                textAlign: 'center',
                paddingLeft: 24,
                paddingRight: 24,
            }}>
                Like this profile? Download Bloom to match with them!
            </p>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <EmailForm />
            </div>
        </div>
    )
}

export default withRouter(Profile)